import React from "react";
import styles from "../pages/index.module.css";
import { Content } from "./types";

export const formatText = (
  text: string,
  formats: string[],
  url?: string,
  color?: string
) => {
  let formattedText: React.ReactNode = text.split("\n").map((part, index) => (
    <React.Fragment key={index}>
      {index > 0 && <br />}
      {part}
    </React.Fragment>
  ));

  if (formats.includes("color")) {
    if (color) {
      formattedText = <span style={{ color }}>{formattedText}</span>;
    } else {
      formattedText = (
        <span className={styles.defaultColor}>{formattedText}</span>
      );
    }
  }

  formats.forEach((format) => {
    switch (format) {
      case "bold":
        formattedText = <strong>{formattedText}</strong>;
        break;
      case "italic":
        formattedText = <em>{formattedText}</em>;
        break;
      case "underline":
        formattedText = <u>{formattedText}</u>;
        break;
      case "link":
        formattedText = (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {formattedText}
          </a>
        );
        break;
      case "mark":
        formattedText = <span className={styles.mark}>{formattedText}</span>;
        break;
      case "list":
        formattedText = (
          <span className={styles.listItem}>{formattedText}</span>
        );
        break;
      default:
        break;
    }
  });

  return formattedText;
};

export const renderSublists = (sublists: Content[], depth: number) => {
  return (
    <ul style={{ paddingLeft: `${depth * 20}px`, margin: 0 }}>
      {sublists.map((sublist, index) => (
        <li key={index} style={{ listStyleType: getListStyleType(depth) }}>
          {formatText(
            sublist.text,
            sublist.formats ?? [],
            sublist.url,
            sublist.color
          )}
          {sublist.sublists &&
            sublist.sublists.length > 0 &&
            renderSublists(sublist.sublists, depth + 1)}
        </li>
      ))}
    </ul>
  );
};

const getListStyleType = (depth: number): string => {
  switch (depth % 3) {
    case 1:
      return "circle";
    case 2:
      return "square";
    default:
      return "disc";
  }
};
