import React, { ReactNode, useEffect, useState, useRef } from "react";
import Head from "next/head";
import styles from "../pages/index.module.css";
import navbarStyles from "./navbar.module.css";
import footerStyles from "./footer.module.css";
import Link from "next/link";
import { useRouter } from "next/router";
import yingyang from "./yingyang";
import KSeFlink from "./kseflink";

interface LayoutProps {
  children: ReactNode;
  title?: string;
  description?: string;
}

const Layout = ({
  children,
  title = "KSeF-link",
  description = "Default description",
}: LayoutProps) => {
  const router = useRouter();
  const [hiddenLinks, setHiddenLinks] = useState<
    { href: string; label: string; external?: boolean }[]
  >([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isActive = (path: string) => router.pathname === path;

  useEffect(() => {
    const handleResize = () => {
      const linksData = [
        { href: "/documentation", label: "Dokumentacja" },
        { href: "/faq", label: "FAQ" },
        { href: "/changes", label: "Zmiany" },
        {
          href: "https://tgsoft.pl/Download.aspx",
          label: "Demo",
          external: true,
        },
        { href: "/price", label: "Cena" },
        { href: "/contact", label: "Kontakt" },
        { href: "https://tgsoft.pl", label: "TGSoft", external: true },
      ];

      const hiddenLinks = linksData.filter((link, index) => {
        const linkElement = document.getElementById(`nav-link-${index}`);
        return linkElement && getComputedStyle(linkElement).display === "none";
      });

      setHiddenLinks(hiddenLinks);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
  };

  const linksData = [
    { href: "/documentation", label: "Dokumentacja" },
    { href: "/faq", label: "FAQ" },
    { href: "/changes", label: "Zmiany" },
    { href: "https://tgsoft.pl/Download.aspx", label: "Demo", external: true },
    { href: "/price", label: "Cena" },
    { href: "/contact", label: "Kontakt" },
    { href: "https://tgsoft.pl", label: "TGSoft", external: true },
  ];

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="KSeF, e-faktura, Polska, oprogramowanie, fakturowanie, TGSoft" />
        {/* Add any other meta tags or link tags here */}
      </Head>
      <main className={styles.main}>
        <nav className={navbarStyles.navbar}>
          <Link href="/" className={navbarStyles.navLogo}>
            {KSeFlink()}
          </Link>
          <div className={navbarStyles.navItems}>
            <div className={navbarStyles.navLinks}>
              {linksData.map((link, index) =>
                link.external ? (
                  <a key={index} id={`nav-link-${index}`} href={link.href}>
                    {link.label}
                  </a>
                ) : (
                  <Link
                    key={index}
                    id={`nav-link-${index}`}
                    href={link.href}
                    className={isActive(link.href) ? navbarStyles.active : ""}
                  >
                    {link.label}
                  </Link>
                )
              )}
            </div>
            {hiddenLinks.length > 0 && (
              <div className={navbarStyles.dropdown} ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className={navbarStyles.dropdownButton}
                  ref={buttonRef}
                >
                  ⋯
                </button>
                {isDropdownOpen && (
                  <div className={navbarStyles.dropdownContent}>
                    {hiddenLinks.map((link, index) =>
                      link.external ? (
                        <a
                          key={index}
                          href={link.href}
                          onClick={handleLinkClick}
                        >
                          {link.label}
                        </a>
                      ) : (
                        <Link
                          key={index}
                          href={link.href}
                          className={
                            isActive(link.href) ? navbarStyles.active : ""
                          }
                          onClick={handleLinkClick}
                        >
                          {link.label}
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
            {yingyang()}
          </div>
        </nav>
        <hr className={styles.sectionSeparator} />
        {children}
        <footer className={footerStyles.footer}>
          <div className={footerStyles.footerLinks}>
            <div className={footerStyles.footerColumn}>
              <div className={footerStyles.footerColumnTitle}>
                Polecane Produkty
              </div>
              <a
                href="https://program-jpk.pl"
                className={footerStyles.footerLink}
              >
                Program-JPK
              </a>
              <a
                href="https://program-esf.pl"
                className={footerStyles.footerLink}
              >
                Program-eSF
              </a>
              <a
                href="https://tgsoft.pl/ksiega-express.aspx"
                className={footerStyles.footerLink}
              >
                Księga-Express
              </a>
            </div>
            <div className={footerStyles.footerColumn}>
              <div className={footerStyles.footerColumnTitle}>KSeF-link</div>
              <Link
                href="/documentation"
                className={`${footerStyles.footerLink} ${
                  isActive("/documentation") ? footerStyles.active : ""
                }`}
              >
                Dokumentacja
              </Link>
              <Link
                href="/faq"
                className={`${footerStyles.footerLink} ${
                  isActive("/faq") ? footerStyles.active : ""
                }`}
              >
                FAQ
              </Link>
              <Link
                href="/changes"
                className={`${footerStyles.footerLink} ${
                  isActive("/changes") ? footerStyles.active : ""
                }`}
              >
                Zmiany
              </Link>
              <a
                href="https://tgsoft.pl/Download.aspx"
                className={footerStyles.footerLink}
              >
                Demo
              </a>
              <Link
                href="/price"
                className={`${footerStyles.footerLink} ${
                  isActive("/price") ? footerStyles.active : ""
                }`}
              >
                Cena
              </Link>
            </div>
            <div className={footerStyles.footerColumn}>
              <div className={footerStyles.footerColumnTitle}>O Firmie</div>
              <a href="https://tgsoft.pl" className={footerStyles.footerLink}>
                TGSoft
              </a>
              <Link
                href="/contact"
                className={`${footerStyles.footerLink} ${
                  isActive("/contact") ? footerStyles.active : ""
                }`}
              >
                Kontakt
              </Link>
            </div>
          </div>
          <div className={footerStyles.footerDivider}></div>
          <div className={footerStyles.footerText}>
            Copyright © {new Date().getFullYear()} TGSoft. All rights reserved.
          </div>
        </footer>
      </main>
    </>
  );
};

export default Layout;
