import React from "react";
import styles from "../pages/index.module.css";

const KSeFlink = () => (
  <>
    <span className={styles.linkColor}>KS</span>
    <span className={styles.redLetter}>e</span>
    <span className={styles.linkColor}>F-link</span>{" "}
  </>
);

export default KSeFlink;
