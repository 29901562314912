import { useState, useEffect } from "react";
import Image from "next/image";

const toggleTheme = () => {
  const bodyClassList = document.body.classList;
  if (bodyClassList.contains("light-theme")) {
    bodyClassList.remove("light-theme");
    bodyClassList.add("dark-theme");
    localStorage.setItem("theme", "dark-theme");
  } else {
    bodyClassList.remove("dark-theme");
    bodyClassList.add("light-theme");
    localStorage.setItem("theme", "light-theme");
  }
};

const Yingyang = () => {
  const [size, setSize] = useState({ width: 25, height: 25 });

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth <= 700) {
        setSize({ width: 18, height: 18 });
      } else if (window.innerWidth <= 1200) {
        setSize({ width: 20, height: 20 });
      } else {
        setSize({ width: 25, height: 25 });
      }
    };

    const loadTheme = () => {
      const theme = localStorage.getItem("theme");
      if (theme) {
        document.body.classList.add(theme);
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    loadTheme();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Image
      src="/images/colour_scheme_switch_icon.webp"
      alt="Toggle Theme"
      width={size.width}
      height={size.height}
      onClick={toggleTheme}
    />
  );
};

export default Yingyang;
